import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FrontHeader from "../components/FrontHeader"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const frontHeaderImage = getImage(data.frontHeader)
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Magnus" />
      <div className="index">
        <FrontHeader img={frontHeaderImage}/>
      </div>
    </Layout>
  )
}

export default BlogIndex
export const frontPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    frontHeader: file(relativePath: { eq: "Magnus-3.jpg" }) {
       childImageSharp {
         gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
