import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import {Link} from "gatsby";
import {GiCalendar, GiSoundWaves} from 'react-icons/gi'
import {FaCameraRetro} from 'react-icons/fa'
import {FaInfo} from 'react-icons/fa';
const FrontHeader = (props) => {
  return(
    <div className="frontHeader">
      <GatsbyImage image={props.img} alt="Profile articstic picture of Magnus Ingemund Kjelstad" />
      <div className="flex center column h1 basePad">
        <h1>MAGNUS INGEMUND KJELSTAD</h1>
        <h2>Barytone</h2>
      </div>
      <div className="frontNavLinks">
        <div className="box">
          <Link to="/bio">
            <FaInfo />
          </Link>
        </div>
        <div className="box">
          <Link to="/images">
            <FaCameraRetro />
          </Link>
        </div>
        <div className="box">
          <Link to="/calendar">
            <GiCalendar />
          </Link>
        </div>
        <div className="box">
          <Link to="/media">
            <GiSoundWaves />
          </Link>
        </div>
      </div>
    </div>
  )
}
export default FrontHeader;
